.beginningOfPortfolioDiv {
  background: url("https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/UUID-cl8sxqsbe0861yuqyftswyj7f/public") no-repeat center;
  background-size: cover;
  margin-bottom: 40px;
  vertical-align: middle;
}

.profilePic {
  height: 30vh;
  width: 30vh;
  border-radius: 100%;
  border: 7px solid rgb(255, 255, 255);
  overflow: hidden;
  margin: 4vh 0;
}

.navigation-buttons {
  font-family: "Roboto";
}