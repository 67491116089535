.card-img {
  filter: grayscale(30%);
  float: left;
  margin-right: 30px;
  max-width: 30ch;
  width: 35vw;
  border-radius: 10px;
}

p {
  font-size:larger;
}
